var SERVER = {
  // DEV: "http://35.175.206.158:80",
  LOCAL: "http://192.168.0.134:4400",
  UAT: "https://dev-webservices.niagarawater.com",
  PROD: "https://webservices.niagarawater.com",
  PILOT: "https://pilot-webservices.niagarawater.com",
};

var BASE_URL = SERVER.PILOT;

module.exports = {
  CONFIG: {
    getFacilities: `${BASE_URL}/api/v1/facilities`,
    queryCheckout: `${BASE_URL}/api/v1/queryCheckout`,
    internalAccess: `${BASE_URL}/api/v1/internalAccess`,
    authorizeSso: `${BASE_URL}/api/v1/authorizeSso`,
    queryShipment: `${BASE_URL}/api/v1/queryShipment`,
    orderCheckIn: `${BASE_URL}/api/v1/orderCheckIn`,
    getCarrierInfo: `${BASE_URL}/api/v1/masterInfo`,
    delivery: `${BASE_URL}/api/v1/delivery`,
    dropEmpty: `${BASE_URL}/api/v1/dropEmptyTrailer`,
    orderCheckout: `${BASE_URL}/api/v1/orderCheckout`,
    feedBack: `${BASE_URL}/api/v1/feedback`,
    getIp: `https://jsonip.com/`,
    checkinAndDropEmpty: `${BASE_URL}/api/v1/checkinAndDropEmpty`,
    scanCode: `${BASE_URL}/api/v1/scanCode`,
    exitGate: `${BASE_URL}/api/v1/exitGate`,
    saveExitGateData: `${BASE_URL}/api/v1/saveExitGateData`,
    getCICOFeatureList: `${BASE_URL}/api/v1/getCICOFeatureList`,
  },
};
